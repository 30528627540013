main {
  overflow-x: hidden;
  overflow-x: clip;
}

.reveal{
  transform: translateX(150px);
  opacity: 0;
  transition: 2s all ease;
}

.revealLeft {
  transform: translateX(-150px);
  opacity: 0;
  transition: 2s all ease;
}

.reveal.active{
  transform: translateX(0);
  opacity: 1;
}

.brand, .splash {
  font-family: 'Splash', cursive;
}

.project-details {
  display: none;
  position: absolute;
  width: 80vw;
  top: 15%;
  left: 10vw;
  margin: auto;
  z-index: 1000;
  animation: lightOpen .5s;
  animation-iteration-count: 1;
}

@keyframes lightOpen {
  0% {opacity: 0; }
  10% {opacity: 10%; }
  20% {opacity: 20%; }
  30% {opacity: 30%; }
  40% {opacity: 40%; }
  50% {opacity: 50%; }
  60% {opacity: 60%; }
  70% {opacity: 70%; }
  80% {opacity: 80%; }
  90% {opacity: 90%; }
  100% {opacity: 100%; }
}



/* .project-card {
  width: 100%;
} */